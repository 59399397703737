import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from "react-helmet"

function About() {
    return (
        <>
        <Helmet title="About | MM-develops" />
          <Layout>
              <section>
                <div className="row">
                    <div className="half"></div>
                    <div className="half">
                        <h2>Hello again!</h2>
                        <p>I'll start this section by confessing that I'm not a real developer, I studied an engineering degree in digital graphic design, however midway through I discovered that my real passion was development. It was too late to switch carriers so I decided to stay put and to take as many extracurriculars on development as I could. So here I am, a developer with a strong background in graphic design.</p>
                        <p>I started working at a branding agency while still in school where I got the opportunity to become a real front end developer. After that I had the great opportunity to work for a large retail brand that allows me to work with many other developers! I'm still growing and love to collaborate on other projects on my free time, so if you have an interesting project you'd like to collaborate on, feel free to reach out!</p>
                        <blockquote>“In some cases we learn more by looking for the answer to a question and not finding it than we do from learning the answer itself.” - Lloyd Alexander, The book of Three</blockquote>
                        { /*<p className="small">p.d The fluffy beauty in the foto is my ferret, Paki. Ferrets are unfairly illegal in california. If you have a moment, it would mean the world to him if you could support the legalize ferret organization. Thank you!!</p>*/ }
                    </div>
                </div>
              </section>
          </Layout>  
        </>
    )
}

export default About
